import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { navigate } from 'gatsby';
import SprinklrIcon from '../../static/assets/sprinklr.svg';
import Home from '../../static/assets/Home.svg';
// import Cover from '../../static/assets/cover.svg';

function generateHeaders(identity) {
  const headers = { 'Content-Type': 'application/json' };
  if (identity && identity.user) {
    return identity.user.jwt().then((token) => {
      return { ...headers, Authorization: `Bearer ${token}` };
    });
  }
  return Promise.resolve(headers);
}

function isLocalhost() {
  try {
    return Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
  } catch (e) {
    return false;
  }
}

function GoogleSSOButton() {
  const { loginProvider } = useIdentityContext();
  const onClick = useCallback(() => loginProvider('google'), [loginProvider]);

  return (
    <button onClick={onClick} className="flex items-center justify-center py-2 rounded-lg text-sm text-white login-btn">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="h-5 w-5 mx-2">
        <path
          fill="#fff"
          d="M5.89 8.86a7 7 0 0110.94-2.04l-2.01 2.01a3.78 3.78 0 00-2.68-1.05 4.17 4.17 0 00-3.91 2.89 4.2 4.2 0 000 2.66 4.2 4.2 0 006.29 2.22 3.23 3.23 0 001.39-2.12h-3.77v-2.7h6.6c.08.45.12.93.12 1.43 0 2.13-.76 3.92-2.09 5.14a6.68 6.68 0 01-4.63 1.7A7 7 0 015.88 8.86h.01z"
        ></path>
      </svg>
      <span>Continue with Sprinklr Email</span>
    </button>
  );
}

async function setSprDevRole(identity) {
  try {
    const headers = await generateHeaders(identity);
    await fetch('/.netlify/functions/set-spr-dev-role', {
      method: 'POST',
      headers,
      body: null,
    }).then((response) => {
      if (!response.ok) {
        return response.text().then((err) => {
          throw err;
        });
      }
    });
  } catch (e) {
    console.error('could not set role', e);
  }
}

async function setUpdatedTokenInCookie(identity) {
  try {
    await identity.user.jwt(true);
  } catch (e) {
    console.error('could not set cookie', e);
  }
}

function EmailVerifier({ user, loading, lineColor }) {
  const {
    user_metadata: { avatar_url },
  } = user;
  const [emailElWidth, setEmailElWidth] = useState(0);
  const emailElRef = useRef();

  useLayoutEffect(() => {
    setEmailElWidth(emailElRef.current.getBoundingClientRect().width);
  }, []);

  const [emailName, emailSuffix] = user.email.split('@');

  return (
    <div className="flex items-center">
      <div>
        <div>{avatar_url && <img className="h-10 w-10 rounded-full mb-4 mx-auto" src={avatar_url} alt="user" />}</div>
        <div className="text-lg">
          <span>{emailName}</span>
          <p className="inline font-extrabold relative" ref={emailElRef}>
            <span>{`@${emailSuffix}`}</span>
            <div
              className={`absolute left-0 email-underline-container overflow-hidden`}
              style={{ width: emailElWidth }}
            >
              <div
                className={`w-full email-underline ${loading ? 'email-underline-loading' : ''}`}
                style={{ backgroundColor: lineColor }}
              />
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

function LoggedInUser({ identity }) {
  const { isLoggedIn, user, logoutUser } = identity;
  const email = user?.email;
  const isValidEmail = email?.endsWith('@sprinklr.com');

  useEffect(() => {
    async function runEffect() {
      if (isLoggedIn && isValidEmail) {
        if (!identity.user.app_metadata?.roles?.includes('spr-dev')) {
          await setSprDevRole(identity);
        }
        await setUpdatedTokenInCookie(identity);
        let returnTo = new URL(window.location).searchParams.get('returnTo') || '';
        if (returnTo.startsWith('design-updates')) {
          window.location.href = `/${returnTo}`;
        } else {
          navigate(`/${returnTo}`);
        }
      }
    }
    runEffect();
  }, [isLoggedIn, isValidEmail, identity]);
  return isValidEmail ? (
    <EmailVerifier user={user} lineColor="#C3D35D" loading />
  ) : (
    <>
      <EmailVerifier user={user} lineColor="#E44045" />
      <button
        className="logout-btn block mx-auto mt-8 border-gray-700 border rounded text-gray-700 hover:border-gray-800 hover:text-gray-800"
        onClick={() => logoutUser()}
      >
        Logout
      </button>
    </>
  );
}

function SignInButton() {
  const identity = useIdentityContext();
  const { isLoggedIn } = identity;

  return (
    <div className="flex flex-col items-center">
      <SprinklrIcon className="h-10 w-10 mb-2" />
      <div className="text-xl font-sans font-semibold mb-4">Welcome To Sprinklr Wiki</div>
      <div className="providersGroup">
        {isLocalhost() && (
          <pre>⚠️Testing providers on localhost won't work because OAuth redirects to your production site</pre>
        )}
        {isLoggedIn ? <LoggedInUser identity={identity} /> : <GoogleSSOButton />}
      </div>
    </div>
  );
}

const Login = () => {
  return (
    <main style={{ height: '100vh' }} className="grid lg:grid-cols-2 items-center">
      <div>
        <SignInButton />
      </div>
      <div className="hidden lg:block">
        {/*<Cover className="w-full mb-5" />*/}
        <Home className="w-full" />
      </div>
    </main>
  );
};

export default Login;
